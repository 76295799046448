import React from "react"
import { Form } from "react-bootstrap"
import { Controller } from "react-hook-form"

export const InputField = ({
  label,
  name,
  type,
  placeholder,
  control,
  trigger,
  rules,
  defaultValue,
  ...moreProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, name, ref },
        formState: { errors }
      }) => {
        // console.log("errors", errors)
        return (
          <Form.Group className="is-invalid mb-3">
            <Form.Label>{label}</Form.Label>
            <Form.Control
              type={type}
              placeholder={placeholder}
              defaultValue={defaultValue}
              onChange={async value => {
                onChange(value)
                trigger(name)
              }}
              onBlur={onBlur}
              ref={ref}
              className={`${errors[name] ? "is-invalid" : ""}`}
              {...moreProps}
            />

            {errors[name]?.message && (
              <Form.Control.Feedback
                type="invalid"
                style={{ display: "block" }}
              >
                {errors[name]?.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        )
      }}
    />
  )
}
