import React, { useEffect } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { useForm, useFieldArray } from "react-hook-form"
import { Col, Row } from "react-bootstrap"
import { CustomModal } from "../../components/CustomModal"
import { CustomButton } from "../../components/CustomButton"
import { PriceListForm } from "./priceListForm"

export const PriceListPopup = ({ show, onHide, data, loading, onSubmit }) => {
  const validation = Yup.object({
    my_store_product_variants: Yup.lazy(() =>
      Yup.array().of(
        Yup.object({
          checked: Yup.boolean(),
          variant_metafields: Yup.array().of(Yup.object({})),
          price: Yup.number("Price must be a number")
            .typeError("Price must be a number")
            .positive("Positive value")
            .test("testing", (value, { parent, createError }) => {
              const { checked, variant_metafields } = parent
              if (!checked) return true
              const minPrice =
                variant_metafields.find(({ name }) => name === "MAP")?.value ||
                0
              const maxPrice =
                variant_metafields.find(({ name }) => name === "MSRP")?.value ||
                Number.POSITIVE_INFINITY
              if (value < minPrice)
                return createError({
                  message: `Price must be greater or equal to $${minPrice}`
                })
              else if (value > maxPrice)
                return createError({
                  message: `Price must be lesser or equal to $${maxPrice}`
                })
              else return true
            })
            .required("Price is required")
        })
      )
    )
  })

  const formOptions = { resolver: yupResolver(validation) }
  const { control, trigger, watch, reset, handleSubmit } = useForm({
    ...formOptions,
    defaultValues: {
      my_store_product_variants: [...data]
    }
  })
  const { fields } = useFieldArray({
    control,
    name: "my_store_product_variants"
  })
  const { my_store_product_variants } = watch()

  useEffect(() => {
    if (!show) {
      reset()
    }
  }, [show])

  return (
    <CustomModal
      size="md"
      show={show}
      onHide={onHide}
      showHeader={true}
      headerTitle="Selling Price"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="priceListForm">
        <Row style={{ maxHeight: "60vh", overflowY: "auto" }}>
          <PriceListForm control={control} trigger={trigger} fields={fields} />
        </Row>
        <Row className="justify-content-center mt-4">
          <Col xs="6" lg="4">
            <CustomButton
              label="Cancel"
              variant="outline-primary"
              type="button"
              onClick={onHide}
            />
          </Col>
          <Col xs="6" lg="4">
            <CustomButton
              label="Save and Add"
              variant="primary"
              type="submit"
              disabled={
                my_store_product_variants.filter(({ checked }) => checked)
                  .length === 0
              }
              loading={loading}
            />
          </Col>
        </Row>
      </form>
    </CustomModal>
  )
}
