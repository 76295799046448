import React from "react"
import "./Chip.scss"

export const Chip = ({ label, variant = "square", className, ...rest }) => {
  return (
    <div {...rest} className={`chip text-nowrap ${variant} ${className} `}>
      {label}
    </div>
  )
}
