import React, { useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { CustomCard } from "../../../components/CustomCard"
import { useForm } from "react-hook-form"
import { Col, Row } from "react-bootstrap"
import { useUserProfileContext } from "../../../contexts/UserProfileContext"
import { CustomButton } from "../../../components/CustomButton"
import { InputField } from "../../../components/InputField"
import { useAuthContext } from "../../../contexts/AuthContext"

export const Feedback = () => {
  const { isLoading, sendFeedback } = useUserProfileContext()
  const { notifySuccess } = useAuthContext()
  const [message, setMessage] = useState("")

  const formSchema = Yup.object().shape({
    message: Yup.string().required("Message is required")
  })
  const formOptions = { resolver: yupResolver(formSchema) }
  const { handleSubmit, control, trigger, reset, setValue, clearErrors } =
    useForm(formOptions)
  const onSubmit = vals => {
    sendFeedback(vals).then(() => {
      setMessage("")
      reset()
      notifySuccess("Feedback sent successfully!")
    })
  }

  return (
    <CustomCard title="Feedback" className="contact-info">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs="12">
            <InputField
              label=""
              name="message"
              as="textarea"
              placeholder="Please enter your feedback here..."
              control={control}
              trigger={trigger}
              value={message}
              onChange={e => {
                const { value } = e.target
                if (value) {
                  clearErrors("message")
                }
                setValue("message", value)
                setMessage(value)
              }}
            />
          </Col>
        </Row>
        <Row className="justify-content-end mt-5">
          <Col xs="4">
            <CustomButton
              label="Cancel"
              variant="outline-primary"
              type="button"
              onClick={() => {
                reset({
                  message: ""
                })
                setMessage("")
              }}
            />
          </Col>
          <Col xs="4">
            <CustomButton
              label="Save"
              variant="primary"
              type="submit"
              loading={isLoading}
            />
          </Col>
        </Row>
      </form>
    </CustomCard>
  )
}
