import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Card from "react-bootstrap/Card"
import { assets } from "../utils"
import "./AuthContainer.scss"

export const AuthContainer = ({
  title,
  subTitle,
  icon,
  className,
  children
}) => {
  const getImgSrc = () => {
    if (className === "login") {
      return assets.LoginCardImg
    } else if (className === "subscriptions" || className === "payment") {
      return assets.SubscriptionCardImg
    } else if (className === "shopifyLogin") {
      return null
    } else if (className === "admin-user-list-container login") {
      return assets.AdminPortalImg
    } else {
      return assets.AuthCardImg
    }
  }
  return (
    <Container className={`auth-container ${className}`} fluid>
      <div className="centered-container">
        <Card className={`${className}-card`}>
          <Card.Img
            loading="lazy"
            variant="top"
            src={getImgSrc() || assets.AuthCardImg}
            className="d-none d-lg-block"
          />
          <Card.Body style={{ minHeight: "530px" }}>
            {icon ? (
              <Row>
                <Col xs={12} className="d-flex align-items-baseline">
                  {icon}
                  <Card.Title className={`${className} mb-3 ms-3`}>
                    {title}
                  </Card.Title>
                </Col>
              </Row>
            ) : (
              title && (
                <Card.Title className={`${className} mb-3`}>{title}</Card.Title>
              )
            )}
            {subTitle && (
              <Card.Text className={`${className} ms-3`}>{subTitle}</Card.Text>
            )}
            {children}
          </Card.Body>
        </Card>
      </div>
    </Container>
  )
}
