import React from "react"
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Line
} from "recharts"

export const StatisticsChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%" minHeight="145px">
      <ComposedChart
        width={400}
        height={300}
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0
        }}
      >
        <CartesianGrid stroke="#f5f5f5" vertical={false} />
        <XAxis dataKey="date" axisLine={{ stroke: "#8A8A8A" }} />
        <Tooltip />
        {/* <Legend verticalAlign="top" align="left" height={36} /> */}
        <Bar
          dataKey="total_sales"
          barSize={20}
          fill="#27963C"
          yAxisId="left"
          legendType="rect"
          name="Total Sales"
        />

        <Line
          dot={false}
          strokeWidth={2}
          strokeLinecap="round"
          type="monotone"
          dataKey="total_order"
          stroke="#3B7AD9"
          yAxisId="right"
          legendType="rect"
          name="Total Order"
        />
        <YAxis
          tickLine={false}
          yAxisId="left"
          axisLine={{ stroke: "#f5f5f5" }}
          domain={[0, 10000]}
        />
        <YAxis
          tickLine={false}
          yAxisId="right"
          orientation="right"
          axisLine={{ stroke: "#f5f5f5" }}
          domain={[0, 100]}
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}
