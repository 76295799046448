export const formData = [
  {
    label: "Email",
    name: "username",
    type: "email",
    placeholder: "Please enter a valid email",
    rules: { required: "Email is required!" }
  },

  {
    label: "Password",
    name: "password",
    type: "password",
    placeholder: "Please enter a password",
    rules: { required: "Password is required!" }
  }
]
