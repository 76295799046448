import React, { createContext, useContext, useState } from "react"
import { API } from "../api"

const UserProfileContext = createContext()

const UserProfileProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [userProfile, setUserProfile] = useState({})
  const [timeZones, setTimeZones] = useState([])
  const [paymentProfiles, setPaymentProfiles] = useState([])
  const [subscriptions, setSubscriptions] = useState([])
  const [currentSubscription, setCurrentSubscription] = useState([])
  const [activeTab, setActiveTab] = useState("account")
  const [users, setUsers] = useState([])

  const getLoginUserProfile = () => {
    // setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getLoginProfile()
        .then(response => {
          setUserProfile(response.data.data)
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getTimezones = () => {
    setIsLoading(true)
    API.getTimezones()
      .then(response => {
        setTimeZones(
          response.data.data.map(({ name, value }) => ({ label: name, value }))
        )
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const updateUserProfile = (id, payload) => {
    setIsLoading(true)
    const formData = new FormData()
    for (let key in payload) {
      if (payload[key]) {
        formData.append(key, payload[key])
      }
    }
    if (payload.address_line2 == "undefined" || payload.address_line2 == "") {
      formData.append("address_line2", "")
    }
    return new Promise((resolve, reject) => {
      API.updateUserProfile(id, formData)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getPaymentProfiles = config => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getPaymentProfiles(config)
        .then(response => {
          setPaymentProfiles(response.data.data.results)
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const addPaymentProfile = payload => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.addPaymentProfile(payload)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const deletePaymentProfile = id => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.deletePaymentProfile(id)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getSubscriptions = () => {
    setIsLoading(true)
    API.getSubscriptions()
      .then(response => {
        const { results } = response.data.data
        setSubscriptions([...results, ...eliteSubscription])
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const addSubscriptionRequest = payload => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.addSubscriptionRequest(payload)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getCurrentSubscription = () => {
    setIsLoading(true)
    API.getCurrentSubscription()
      .then(response => setCurrentSubscription(response.data.data.results))
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const makePaymentDefault = id => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.makePaymentDefault(id)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const activateAutoPay = (id, payload) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.autoPay(id, payload)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const unsubscribePlan = id => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.unsubscribePlan(id)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getAmountToSubscribe = config => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getAmountToSubscribe(config)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const sendFeedback = payload => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.sendFeedback(payload)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getUserList = config => {
    setIsLoading(true)
    API.getUserList(config)
      .then(response => setUsers(response.data.data.results))
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  return (
    <UserProfileContext.Provider
      value={{
        isLoading,
        userProfile,
        getLoginUserProfile,
        timeZones,
        getTimezones,
        updateUserProfile,
        paymentProfiles,
        getPaymentProfiles,
        setPaymentProfiles,
        addPaymentProfile,
        deletePaymentProfile,
        subscriptions,
        getSubscriptions,
        addSubscriptionRequest,
        currentSubscription,
        getCurrentSubscription,
        makePaymentDefault,
        activateAutoPay,
        unsubscribePlan,
        getAmountToSubscribe,
        sendFeedback,
        activeTab,
        setActiveTab,
        users,
        getUserList
      }}
    >
      {children}
    </UserProfileContext.Provider>
  )
}

export const useUserProfileContext = () => useContext(UserProfileContext)

export default UserProfileProvider

const eliteSubscription = [
  {
    id: null,
    name: "Elite",
    description:
      "For further information, regarding pricing and to, establish a tailored plan for your company, please reach out to us",
    price: "",
    interval: "per month",
    mostPopular: false
  }
]
