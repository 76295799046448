import { Container, Row, Col } from "react-bootstrap"
import { assets } from "../../utils"
import { CustomAgGridTable } from "../../components/CustomAgGridTable"
import { column } from "./columns"
import { useNavigate, useParams } from "react-router-dom"
import { PaymentCard } from "./paymentCard"
import { ShippingCard } from "./shippingCard"
import { NoteCard } from "./noteCard"
import { useEffect, useState } from "react"
import { useOrdersContext } from "../../contexts/OrdersContext"
import { CustomSpinner } from "../../components/CustomSpinner"
import { useAuthContext } from "../../contexts/AuthContext"
import { InfoPopup } from "../../components/InfoPopup"
import { useUserProfileContext } from "../../contexts/UserProfileContext"
import { CreditCard } from "../../components/CreditCard"
import { ShippingDetails } from "./shippingdetails"
import "./ordersdetails.scss"

export const OrdersDetails = () => {
  const nav = useNavigate()
  const params = useParams()
  const { notifySuccess } = useAuthContext()
  const { paymentProfiles, getPaymentProfiles, setActiveTab } =
    useUserProfileContext()
  const {
    isLoading,
    order,
    getOrder,
    addOrderNote,
    updateOrderNote,
    raiseOrderRequest,
    deleteOrderNote,
    rates
  } = useOrdersContext()
  const {
    name,
    common_status_descr,
    order_line_items,
    shipping_address,
    order_note,
    total_cost_price,
    service_fee
  } = order

  const [show, setShow] = useState(false)
  const onHide = () => setShow(false)
  const [showShipping, setShowShipping] = useState(false)
  const onHideShipping = () => setShowShipping(false)
  const [shippingFee, setShippingFee] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [shippingDetails, setShippingDetails] = useState({})
  const [shippingMode, setShippingMode] = useState(false)
  const [shippingName, setShippingName] = useState("")
  const [payInProgress, setPayInProgress] = useState(false)

  const onClickPay = () => {
    setPayInProgress(true)
    raiseOrderRequest(shippingDetails)
      .then(() => {
        setShow(false)
        setPayInProgress(false)
        setShippingMode(true)
        notifySuccess("Amount paid successfully!")
        getOrder(params.id)
      })
      .catch(err => setPayInProgress(false))
  }

  const onSubmit = vals => {
    const { total_cost_price, service_fee } = order
    const { shipping_carrier, shipping_code } = vals
    const { total_cost, serviceName } = rates.find(
      ({ value }) => value === shipping_code
    )
    setShippingFee(total_cost)
    setShippingName(serviceName)
    setTotalAmount(
      Number(total_cost_price) + Number(service_fee) + Number(total_cost)
    )
    setShippingDetails({ order: params.id, shipping_carrier, shipping_code })
    setShippingMode(true)
    setShowShipping(false)
  }

  useEffect(() => {
    if (order?.id) {
      const {
        shipping_fee,
        total_amount_paid,
        total_cost_price,
        service_fee,
        shipping_name
      } = order
      setShippingFee(shipping_fee)
      setShippingName(shipping_name)
      if (total_amount_paid > 0) {
        setTotalAmount(total_amount_paid)
      } else {
        setTotalAmount(Number(total_cost_price) + Number(service_fee))
      }
    }
  }, [order])

  useEffect(() => {
    if (params?.id) {
      getOrder(params.id)
    }
  }, [params?.id])

  useEffect(() => {
    getPaymentProfiles({ params: { is_default: true } })
  }, [])

  return (
    <Container className="orders-details-container" fluid>
      <Row className="p-1">
        <Col
          xs="10"
          md="11"
          className="d-flex align-items-center"
          onClick={() => nav("/orders")}
          style={{ cursor: "pointer" }}
        >
          <img src={assets.BackArrowIcon} />
          <h5 className="page-title mb-0 ms-1">{`Order - ${name}`}</h5>
        </Col>
        <Col xs="2" md="1" className="d-flex align-items-center">
          <h5 className="page-title">{common_status_descr}</h5>
        </Col>
      </Row>
      <Row className="p-1 mb-3">
        <CustomAgGridTable
          rowData={order_line_items}
          columnDefs={column}
          pagination={false}
          onRowClicked={({ data: { original_product } }) => {
            if (original_product) {
              nav(`/browse-products/${original_product}`)
            }
          }}
        />
      </Row>
      <Row>
        <Col md="3" xs="12" className="mb-3">
          <PaymentCard
            status={common_status_descr}
            total_cost_price={total_cost_price}
            total_amount_paid={totalAmount}
            service_fee={service_fee || null}
            shipping_fee={shippingFee}
            onClick={() => setShow(true)}
            shippingMode={shippingMode}
            onClickSelectMode={() => setShowShipping(true)}
          />
        </Col>
        <Col md="3" xs="12" className="mb-3">
          <ShippingCard {...shipping_address} shippingName={shippingName} />
        </Col>
        <Col md="6" xs="12" className="mb-3">
          <NoteCard
            key={params.id}
            order_note={order_note}
            order={params.id}
            addOrderNote={addOrderNote}
            updateOrderNote={updateOrderNote}
            getOrder={getOrder}
            deleteOrderNote={deleteOrderNote}
            isLoading={isLoading}
          />
        </Col>
      </Row>
      <InfoPopup
        size="md"
        show={show}
        onHide={onHide}
        title="Payment Confirmation"
        subTitle={
          <Row className="justify-content-center">
            <Col lg="8" xl="8" xxl="8">
              <CreditCard {...paymentProfiles[0]} isLoading={isLoading} />
            </Col>
          </Row>
        }
        btnTitle="Confirm Payment"
        isLoading={payInProgress}
        onClick={onClickPay}
        onClickLink={() => {
          setActiveTab("billing")
          nav("/settings")
        }}
      />
      <ShippingDetails
        show={showShipping}
        onHide={onHideShipping}
        orderId={params.id}
        onSubmit={onSubmit}
      />
      <CustomSpinner loading={isLoading} />
    </Container>
  )
}
