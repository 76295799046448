import React, { useState, useEffect } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useNavigate } from "react-router-dom"
import { CustomContainer } from "../../../components/CustomContainer"
import { Col, Container, Row } from "react-bootstrap"
import { Filters } from "../../../components/Filters"
import { Search } from "../../../components/Search"
import { useBrowseProductsContext } from "../../../contexts/BrowseProductsContext"
import { CustomSpinner } from "../../../components/CustomSpinner"
import { assets, debounce, getNextPage } from "../../../utils"
import { OffCanvasExample } from "../../../components/CustomOffcanvas"
import { PriceListPopup } from "../../productdetails/priceListPopup"
import { useAuthContext } from "../../../contexts/AuthContext"
import { ConfirmPopup } from "./confirmPopup"
import { Products } from "../products"
import { CustomButton } from "../../../components/CustomButton"
import { Chip } from "../../../components/Chip"
import "./browseproducts.scss"

export const BrowseProducts = () => {
  const nav = useNavigate()
  const {
    isLoading,
    browseProducts,
    getBrowseProducts,
    brands,
    productTypes,
    filterParams,
    setFilterParams,
    setBrands,
    setProductTypes,
    product,
    getBrowseProduct,
    addProductToMyStore,
    removeProductFromMyStore,
    suggestedActive,
    setSuggestedActive
  } = useBrowseProductsContext()
  const { notifySuccess } = useAuthContext()
  const { next, results } = browseProducts
  const [show, setShow] = useState(false)
  const [showPricePopup, setShowPricePopup] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleClosePricePopup = () => setShowPricePopup(false)
  const [productId, setProductId] = useState(null)
  const [idToRemove, setIdToRemove] = useState(null)
  const [showConfirm, setShowConfirm] = useState(false)
  const onHideConfirm = () => {
    setIdToRemove(null)
    setShowConfirm(false)
  }
  const [selectedProducts, setSelectedProducts] = useState([])
  const [showConfirmAdd, setShowConfirmAdd] = useState(false)
  const onHideConfirmAdd = () => {
    setShowConfirmAdd(false)
  }

  const [showAdd, setShowAdd] = useState(false)
  const onHideAdd = () => {
    setProductId(null)
    setShowAdd(false)
  }

  const getStockStatus = ({ inStock, outOfStock }) => {
    if ((inStock && outOfStock) || (!inStock && !outOfStock)) {
      return
    } else if (inStock) {
      return true
    } else if (outOfStock) {
      return false
    }
  }

  const loadMoreProducts = params => {
    const {
      page,
      price_min,
      price_max,
      product_type,
      vendor,
      search,
      suggested
    } = params
    console.log("params", params);
    const config = {
      params: {
        page,
        search,
        price_min,
        price_max,
        in_stock: getStockStatus(params),
        vendor: vendor?.join(","),
        product_type: product_type?.join(","),
        suggested
      }
    }
    getBrowseProducts(config)
  }

  const loadMoreHandler = () => {
    if (next) {
      const page = getNextPage(next)
      loadMoreProducts({...filterParams, page})
    }
  }

  const onClickProduct = id => {
    nav(`/browse-products/${id}`)
  }

  /* Filter Utility **/
  const onSubmit = vals => {
    setFilterParams(() => ({ ...filterParams, ...vals }))
  }

  const onChangeHandler = vals => {
    const { checked, name, value } = vals
    if (!checked) {
      filterParams[name] = filterParams[name]?.filter(val => val !== value)
    } else {
      filterParams[name]?.push(value)
    }
    setFilterParams(() => ({ ...filterParams }))
  }

  const onChangeSearch = debounce(
    text => setFilterParams({ ...filterParams, search: text }),
    300
  )

  const onClickInStock = checked => {
    setFilterParams({ ...filterParams, inStock: checked })
  }

  const onClickOutOfStock = checked => {
    setFilterParams({ ...filterParams, outOfStock: checked })
  }

  useEffect(() => {
    loadMoreProducts({ ...filterParams, page: 1 })
  }, [filterParams])

  /* END **/

  /** Submit prices */
  const onSubmitPrices = vals => {
    const { my_store_product_variants } = vals
    const payload = []
    vals.product = product.id
    vals.my_store_product_variants = my_store_product_variants.map(
      ({ id, price }) => ({
        variant: id,
        price
      })
    )
    payload.push(vals)
    addProductToMyStore(payload).then(() => {
      setShowPricePopup(false)
      notifySuccess("Products added successfully!")
    })
  }

  /** Click Add */
  const onClickAddHandle = () => {
    const payload = selectedProducts.map(product => ({ product }))
    addProductToMyStore(payload).then(() => {
      setShowConfirmAdd(false)
      notifySuccess("Products will be added to your store after sometime.")
      setSelectedProducts([])
    })
  }

  useEffect(() => {
    if (productId) {
      setShowAdd(true)
    }
  }, [productId])

  return (
    <Container fluid className="BrowseProducts">
      <Row>
        <Col
          md="3"
          lg="3"
          xl="2"
          id="filters-wrapper"
          className="p-0 d-none d-md-block"
        >
          <OffCanvasExample
            key={"idx12345"}
            placement={"end"}
            name={"end"}
            className="d-md-none"
            show={show}
            handleShow={handleShow}
            handleClose={handleClose}
          >
            <Filters
              priceMin={filterParams.price_min}
              priceMax={filterParams.price_max}
              brands={brands}
              productTypes={productTypes}
              onSubmit={onSubmit}
              inStock={filterParams.inStock}
              outOfStock={filterParams.outOfStock}
              onClickInStock={onClickInStock}
              onClickOutOfStock={onClickOutOfStock}
              onChangeHandler={onChangeHandler}
              setBrands={setBrands}
              setProductTypes={setProductTypes}
              showInStock={true}
            />
          </OffCanvasExample>
          <div
            className="filters-container"
            style={{
              maxHeight: "100vh",
              overflow: "hidden",
              overflowY: "auto"
            }}
          >
            <Filters
              priceMin={filterParams.price_min}
              priceMax={filterParams.price_max}
              brands={brands}
              productTypes={productTypes}
              onSubmit={onSubmit}
              inStock={filterParams.inStock}
              outOfStock={filterParams.outOfStock}
              onClickInStock={onClickInStock}
              onClickOutOfStock={onClickOutOfStock}
              onChangeHandler={onChangeHandler}
              setBrands={setBrands}
              setProductTypes={setProductTypes}
              showInStock={true}
            />
          </div>
        </Col>
        <Col md="9" lg="9" xl="10" id="page-content-wrapper" className="p-0">
          <CustomContainer title="">
            <Row>
              <Col xs="10" md="9">
                <h3 className="header-title mb-3">Browse Products</h3>
              </Col>
              <Col xs="2" className="d-block d-md-none">
                <img src={assets.FilterIcon} onClick={handleShow} />
              </Col>
              <Col xs="12" md="3" className="mb-3">
                <Search onChange={onChangeSearch} value={filterParams.search} />
              </Col>
            </Row>
            <Row
              className={`add-btn-row mb-2 ${
                selectedProducts.length > 0 ? "visible" : "visible"
              }`}
            >
              <Col xs="4" md="4" lg="1" className=" mb-3">
                <CustomButton
                  label="Add"
                  className={`${
                    selectedProducts.length > 0 ? "visible" : "invisible"
                  }`}
                  disabled={isLoading}
                  onClick={() => setShowConfirmAdd(true)}
                />
              </Col>
              <Col xs="4" md="4" lg="1" className=" mb-3">
                <CustomButton
                  label="Reset"
                  variant="outline-primary"
                  className={`${
                    selectedProducts.length > 0 ? "visible" : "invisible"
                  }`}
                  disabled={isLoading}
                  onClick={() => setSelectedProducts([])}
                />
              </Col>
              <Col xs="4" md="4" lg="1" xl="2" xxl="1" className="ms-auto mb-3">
                <Chip
                  label="Suggested"
                  className={suggestedActive ? "active" : ""}
                  onClick={() => {
                    if (!suggestedActive) {
                      setSuggestedActive(true)
                    } else {
                      setSuggestedActive(false)
                    }
                  }}
                />
              </Col>
            </Row>
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMoreHandler}
              hasMore={true}
              threshold={250}
              useWindow={false}
              initialLoad={false}
            >
              <Products
                products={results}
                onClickProduct={onClickProduct}
                setProductId={product => setProductId(product)}
                setIdToRemove={setIdToRemove}
                setShowConfirm={setShowConfirm}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                isLoading={isLoading}
              />
            </InfiniteScroll>
          </CustomContainer>
        </Col>
      </Row>
      {product?.id && (
        <PriceListPopup
          key={product.id}
          show={showPricePopup}
          onHide={handleClosePricePopup}
          data={[...product.product_catalog_variants]}
          loading={isLoading}
          onSubmit={onSubmitPrices}
        />
      )}
      <ConfirmPopup
        title="Remove Product"
        subTitle="Are you sure want to remove this product ?"
        key={idToRemove}
        show={showConfirm}
        onHide={onHideConfirm}
        isLoading={isLoading}
        btnTitle="Remove"
        onClick={() => {
          removeProductFromMyStore(idToRemove).then(() => {
            setIdToRemove(null)
            setShowConfirm(false)
            notifySuccess("Product removed successfully!")
          })
        }}
      />
      <ConfirmPopup
        title="Add Product"
        subTitle="Are you sure want to add these products ?"
        key={"showConfirmAdd"}
        show={showConfirmAdd}
        onHide={onHideConfirmAdd}
        isLoading={isLoading}
        btnTitle="Add"
        onClick={onClickAddHandle}
      />
      <ConfirmPopup
        title="Add Product"
        subTitle="Are you sure want to add this product ?"
        key={"showAdd"}
        show={showAdd}
        onHide={onHideAdd}
        isLoading={isLoading}
        btnTitle="Add"
        onClick={() => {
          addProductToMyStore([{ product: productId }]).then(() => {
            setShowAdd(false)
            notifySuccess("Product will be added to your store after sometime.")
            setProductId(null)
          })
        }}
      />
      <CustomSpinner loading={isLoading} />
    </Container>
  )
}
