import React from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { InputField } from "../InputField"
import { useForm } from "react-hook-form"
import { formData, formData2 } from "./formData"
import { Col, Row } from "react-bootstrap"
import { Checkbox } from "../Checkbox"
import { CustomButton } from "../CustomButton"

export const PaymentForm = ({
  isLoading,
  addCard = false,
  onCancel,
  onSubmit
}) => {
  const formSchema = Yup.object().shape({
    card: Yup.string()
      .typeError("Invalid card number")
      .matches(/(?:\d[ -]*?){13,16}/, "Invalid card number")
      .min(13, "Must be a valid 13-16 digit card number.")
      .max(16, "Must be a valid 13-16 digit card number.")
      .required("Card Number is required"),
    name: Yup.string()
      .trim()
      .min(3, "Name must be at 3 digit long")
      .matches(/^[a-zA-Z ]+$/, "Invalid name")
      .required("Card Holder Name is required"),

    expDate: Yup.string().required("Expiration Date is required"),
    cvv: Yup.string()
      .required("CVV is required")
      .min(3, "CVV must be 3 digit long")
  })
  const formOptions = { resolver: yupResolver(formSchema) }
  const { handleSubmit, control, trigger } = useForm(formOptions)

  const renderFormData = () =>
    formData.map(({ label, name, type, placeholder, rules }, index) => (
      <InputField
        key={index}
        label={label}
        name={name}
        type={type}
        placeholder={placeholder}
        control={control}
        trigger={trigger}
        rules={rules}
      />
    ))

  const renderFormData2 = () =>
    formData2.map(({ label, name, dateFormat, type, placeholder, rules }) => (
      <Col key={name} xs="6">
        <InputField
          label={label}
          name={name}
          type={type}
          showMonthYearPicker
          dateFormat={dateFormat}
          placeholder={placeholder}
          placeholderText={placeholder}
          control={control}
          trigger={trigger}
          rules={rules}
        />
      </Col>
    ))

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {renderFormData()}
      <Row className="justify-content-center">
        {renderFormData2()}
        {/* <Col xs="12" className="mb-3 payment-save-card">
          {addCard ? (
            <Checkbox
              key="payment.form-control"
              label="Set as rimary card"
              name="isPrimary"
              type="checkbox"
              control={control}
            />
          ) : (
            <Checkbox
              key="payment.form-control"
              label={"Save my card for future use."}
              name="cardSave"
              type="checkbox"
              control={control}
            />
          )}
        </Col> */}
        {addCard ? (
          <Row className="add-card-payment justify-content-center mt-4">
            <Col xs="5">
              <CustomButton
                label="Cancel"
                variant="outline-primary"
                type="button"
                onClick={onCancel}
              />
            </Col>
            <Col xs="5">
              <CustomButton
                label="Add"
                variant="primary"
                type="submit"
                loading={isLoading}
              />
            </Col>
          </Row>
        ) : (
          <Col xs="11" className="mb-3 mt-4">
            <CustomButton
              label="Confirm Payment"
              variant="primary"
              type="submit"
              loading={isLoading}
            />
          </Col>
        )}
      </Row>
    </form>
  )
}
