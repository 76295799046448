export const formData = [
  {
    label: "Card Number",
    name: "card",
    type: "text",
    placeholder: "1234 5678 9012 3456",
    rules: { required: "Card Number is required!" }
  },
  {
    label: "Card Holder Name",
    name: "name",
    type: "text",
    placeholder: "Card Holder Name",
    rules: { required: "Card Holder Name is required!" }
  },
  {
    label: "Zipcode",
    name: "zip",
    type: "text",
    placeholder: "12345",
    rules: { required: "Zip is required!" }
  }
]
export const formData2 = [
  {
    label: "Expiration Data",
    name: "expDate",
    type: "date",
    placeholder: "07/28",
    dateFormat: "MM/yy",
    rules: { required: "Expiration Date is required!" }
  },
  {
    label: "CVV",
    name: "cvv",
    type: "password",
    placeholder: "123",
    rules: { required: "CVV is required!" }
  }
]
