import React, { useEffect } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { assets } from "../../utils"
import { NavbarOffcanvas } from "../../components/Navbars/NavbarOffcanvas"
import { useBrowseProductsContext } from "../../contexts/BrowseProductsContext"
import { useMyProductsContext } from "../../contexts/MyProductsContext"
import { useDashboardContext } from "../../contexts/DashboardContext"
import { useUserProfileContext } from "../../contexts/UserProfileContext"

const DashboardContainer = ({}) => {
  const location = useLocation()
  const nav = useNavigate()
  const { is_subscribed, is_payment_profile_exists } = JSON.parse(
    localStorage.getItem("user")
  )
  const {
    getBrands,
    getProductTypes,
    suggestedActive,
    filterParams: params,
    setFilterParams: setParams
  } = useBrowseProductsContext()
  const {
    filterParams,
    setFilterParams,
    getBrands: getBrandList,
    getProductTypes: getProductTypeList
  } = useMyProductsContext()
  const { bestsellersActive, prieUpdatesActive } = useDashboardContext()
  const { userProfile, getLoginUserProfile } = useUserProfileContext()
  useEffect(() => {
    Promise.allSettled([
      getBrands(),
      getProductTypes()
      // myProductsContext.getBrands(),
      // myProductsContext.getProductTypes()
    ])
    getLoginUserProfile().then(user => {
      localStorage.setItem("user", JSON.stringify(user))
    })
  }, [])

  useEffect(() => {
    if (suggestedActive) {
      params.suggested = true
      getBrands({ params: { suggested: true } })
      getProductTypes({ params: { suggested: true } })
    } else {
      delete params.suggested
      getBrands()
      getProductTypes()
    }
    setParams({ page: 1, ...params, vendor: [], product_type: [] })
  }, [suggestedActive])

  useEffect(() => {
    if (bestsellersActive) {
      delete filterParams.my_products
      delete filterParams.price_updates
      filterParams.bestseller = true
      getBrandList({ params: { bestseller: true } })
      getProductTypeList({ params: { bestseller: true } })
    } else if (prieUpdatesActive) {
      delete filterParams.my_products
      delete filterParams.bestseller
      filterParams.price_updates = true
      getBrandList({ params: { price_updates: true } })
      getProductTypeList({ params: { price_updates: true } })
    } else {
      delete filterParams.bestseller
      delete filterParams.price_updates
      filterParams.my_products = true
      getBrandList({ params: { my_products: true } })
      getProductTypeList({ params: { my_products: true } })
    }
    setFilterParams({ page: 1, ...filterParams, vendor: [], product_type: [] })
  }, [bestsellersActive, prieUpdatesActive])

  useEffect(() => {
    if (userProfile?.id) {
      const { is_subscribed } = userProfile
      if (!is_subscribed) {
        nav("/subscription")
      }
    }
  }, [location, userProfile])

  const navigations = [
    {
      label: "Dashboard",
      path: "/dashboard",
      icon: assets.DashboardIcon,
      iconActive: assets.DashboardIconActive
    },
    {
      label: "My Products",
      path: "/my-products",
      icon: assets.ProductIcon,
      iconActive: assets.ProductIconActive
    },
    {
      label: "Browse Products",
      path: "/browse-products",
      icon: assets.ProductIcon,
      iconActive: assets.ProductIconActive
    },
    {
      label: "Orders",
      path: "/orders",
      icon: assets.CartIcon,
      iconActive: assets.CartIconActive
    },
    {
      label: "Support",
      path: "/support",
      icon: assets.SupportIcon,
      iconActive: assets.SupportIcon
    }
  ]

  return (
    <>
      <div className="d-flex">
        <div className="d-flex flex-column w-100 min-vh-100 overflow-hidden">
          <NavbarOffcanvas navigations={navigations} />
          <div className="outlet-container">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardContainer
