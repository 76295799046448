import React from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { AuthContainer } from "../../common/AuthContainer"
import { InputField } from "../../components/InputField"
import { useForm } from "react-hook-form"
import { formData } from "./formData"
import { Col, Row } from "react-bootstrap"
import { useAuthContext } from "../../contexts/AuthContext"
import { useNavigate } from "react-router-dom"
import { CustomButton } from "../../components/CustomButton"

export const ForgetPassword = () => {
  const nav = useNavigate()
  const { isLoading, forgetPassword, notifySuccess } = useAuthContext()

  const formSchema = Yup.object().shape({
    email: Yup.string().required("Email is required")
  })
  const formOptions = { mode: "onTouched", resolver: yupResolver(formSchema) }
  const { control, trigger, reset, handleSubmit } = useForm(formOptions)

  const onSubmit = vals => {
    forgetPassword(vals).then(response => {
      reset()
      notifySuccess(`${response.detail} to ${vals.email}`)
    })
  }

  const renderFormData = () => {
    return formData.map(({ label, name, type, placeholder, rules }, index) => (
      <InputField
        key={index}
        label={label}
        name={name}
        type={type}
        placeholder={placeholder}
        trigger={trigger}
        control={control}
        rules={rules}
      />
    ))
  }

  return (
    <AuthContainer title="Forget Password" className="signup">
      <div className="signup-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          {renderFormData()}
          <Row className="justify-content-center mt-5">
            <Col xs="11" className="mb-3">
              <CustomButton
                label="Next"
                variant="primary"
                type="submit"
                loading={isLoading}
              />
            </Col>
          </Row>
        </form>
      </div>
    </AuthContainer>
  )
}
