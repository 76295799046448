import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import { useNavigate } from "react-router-dom"
import "./CustomAgGridTable.scss"

export const CustomAgGridTable = ({
  rowData,
  columnDefs,
  onRowClicked,
  pagination,
  paginationPageSize,
  height
}) => {
  const nav = useNavigate()

  const onPaginationChanged = e => {
    console.log("current-page" + (e.api.paginationGetCurrentPage() + 1))
  }

  return (
    <div
      className="ag-theme-alpine"
      style={{ height: height || "40vh", width: "full" }}
    >
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        pagination={pagination}
        paginationPageSize={paginationPageSize || 5}
        onRowClicked={onRowClicked}
        onPaginationChanged={onPaginationChanged}
      ></AgGridReact>
    </div>
  )
}
