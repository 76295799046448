import React from "react"
import { Form } from "react-bootstrap"
import { assets } from "../../utils"
import "./Search.scss"

export const Search = ({ placeholder, onChange = () => {}, value }) => {
  return (
    <div className="search-container position-relative">
      <Form.Control
        name="searchText"
        type="search"
        label=""
        placeholder={placeholder || "Search in my products"}
        className="me-2"
        aria-label="Search"
        defaultValue={value}
        onChange={e => onChange(e.target.value)}
      />
      <img src={assets.SearchIcon} className="search-icon" />
    </div>
  )
}
