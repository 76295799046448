import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import "./CustomContainer.scss"

export const CustomContainer = ({ title = "", children }) => {
  return (
    <Container fluid className="custom-container">
      {title && (
        <Row>
          <Col className="m-3 ms-0">
            <h3 className="header-title">{title}</h3>
          </Col>
        </Row>
      )}

      {children}
    </Container>
  )
}
