import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import "./CustomModal.scss"

export function CustomModal(props) {
  const {
    size = "lg",
    headerTitle,
    showHeader,
    showFooter,
    children,
    onHide,
    footerBtnText
  } = props
  return (
    <Modal
      {...props}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {showHeader && (
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {headerTitle}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
      {showFooter && (
        <Modal.Footer>
          <Button onClick={onHide}>{footerBtnText || "Close"}</Button>
        </Modal.Footer>
      )}
    </Modal>
  )
}
