import React from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { formData } from "./formData"
import { Col, Row } from "react-bootstrap"
import { useAuthContext } from "../../contexts/AuthContext"
import { InputFieldPassword } from "../../components/InputFieldPassword"
import { useNavigate } from "react-router-dom"
import { CustomCard } from "../../components/CustomCard"
import { CustomButton } from "../../components/CustomButton"

export const ChangePassword = () => {
  const nav = useNavigate()
  const { isLoading, passwordChange, notifySuccess } = useAuthContext()

  const formSchema = Yup.object().shape({
    old_password: Yup.string()
      .required("Old Password is required")
      .min(6, "Password must be at 6 char long"),
    new_password1: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at 6 char long")
      .trim()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        "Password must be at least 8 letters, one capital & small letter, one digit and at least one special symbol from [ ! @ # $ % ^ & * ]"
      ),
    new_password2: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("new_password1")], "Passwords does not match")
  })
  const formOptions = { mode: "onTouched", resolver: yupResolver(formSchema) }
  const { handleSubmit, control } = useForm(formOptions)

  const onSubmit = vals => {
    const payload = {
      ...vals
    }
    passwordChange(payload).then(() => {
      notifySuccess("You have changed your password successfully!")
      // nav("/dashboard")
      nav("/login")
    })
  }

  const renderFormData = () => {
    return formData.map(({ label, name, type, placeholder, rules }, index) => (
      <InputFieldPassword
        key={index}
        label={label}
        name={name}
        type={type}
        placeholder={placeholder}
        control={control}
        rules={rules}
      />
    ))
  }

  return (
    <Row className="d-flex align-items-center justify-content-center">
      <Col lg={4}>
        <CustomCard title="Reset Password" className="">
          <div className="signup-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              {renderFormData()}
              <Row className="justify-content-center mt-5">
                <Col xs="11" className="mb-3">
                  <CustomButton
                    label="Confirm"
                    variant="primary"
                    type="submit"
                    loading={isLoading}
                  />
                </Col>
              </Row>
            </form>
          </div>
        </CustomCard>
      </Col>
    </Row>
  )
}
