import { Row, Container, Col } from "react-bootstrap"
import { useAuthContext } from "../../contexts/AuthContext"
import { useEffect } from "react"
import { stringToHTML } from "../../utils"
import { CustomSpinner } from "../../components/CustomSpinner"

export const PrivacyPolicy = () => {
  const { isLoading, privacyPolicy, getPrivacyPolicy } = useAuthContext()

  useEffect(() => {
    getPrivacyPolicy()
  }, [])

  return (
    <Container>
      <Row className="content p-3">
        <Col>{privacyPolicy && stringToHTML(privacyPolicy)}</Col>
      </Row>
      <CustomSpinner loading={isLoading} />
    </Container>
  )
}
