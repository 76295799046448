import { CustomCard } from "../../components/CustomCard"
import { Row, Col } from "react-bootstrap"
import "./ordersdetails.scss"

export const ShippingCard = ({
  address1,
  address2,
  city,
  company,
  country,
  country_code,
  first_name,
  last_name,
  latitude,
  longitude,
  name,
  province,
  province_code,
  phone,
  zip,
  shippingName
}) => {
  return (
    <CustomCard title="Shipping Details" className="shipping-info">
      <Row>
        <Col xs="12">
          <p className="mb-0">{`${first_name || ""} ${last_name || ""}`}</p>
        </Col>
        <Col xs="12">
          <p className="mb-0">{`${address1 || ""}`}</p>
        </Col>
        <Col xs="12">
          <p className="mb-0">{`${address2 || ""}`}</p>
        </Col>
        <Col xs="12">
          {city && <p className="mb-0">{`${city || ""} - ${zip || ""}`}</p>}
        </Col>
        <Col xs="12">
          {country && (
            <p className="mb-0">{`${country || ""} (${country_code || ""})`}</p>
          )}
        </Col>
        <Col xs="12">
          <p className="mb-0">{phone || ""}</p>
        </Col>
      </Row>
      <Row className="mt-2">
        <h6>{shippingName}</h6>
      </Row>
    </CustomCard>
  )
}
