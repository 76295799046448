import Offcanvas from "react-bootstrap/Offcanvas"

export function OffCanvasExample({ name, ...props }) {
  const { show, handleClose } = props

  return (
    <Offcanvas show={show} onHide={handleClose} {...props}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title></Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>{props.children}</Offcanvas.Body>
    </Offcanvas>
  )
}
