import React from "react"
import { CustomCard } from "../../../components/CustomCard"
import { CustomButton } from "../../../components/CustomButton"
import { assets } from "../../../utils"

export const PaymentPendingCard = ({
  title,
  payment_pending,
  payment_pending_amount,
  onClick
}) => {
  return (
    <CustomCard
      title={title}
      subTitle={
        <div className="d-flex align-items-center">
          <h1
            style={{
              fontWeight: "700",
              fontSize: "40px",
              lineHeight: "18px"
            }}
          >
            {payment_pending || 0}
          </h1>
          <p className="mb-1 ms-2">
            Orders That <br />
            Need Payment
          </p>
        </div>
      }
      src={assets.DollarIcon}
      className="payment-pending"
    >
      <p
        style={{
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "18px"
        }}
      >
        {`Total Value: $${
          payment_pending_amount
            ? Number(payment_pending_amount).toFixed(2)
            : Number(0).toFixed(2)
        }`}
      </p>
      <CustomButton
        label="View All"
        backgroundColor="white"
        type="button"
        onClick={onClick}
      />
    </CustomCard>
  )
}
