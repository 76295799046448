import { Link } from "react-router-dom"

export const formData = [
  {
    label: "Email",
    name: "email",
    type: "email",
    placeholder: "Please enter a valid email",
    rules: { required: "Email is required!" }
  },
  {
    label: "Full Name",
    name: "name",
    type: "text",
    placeholder: "Please enter your full name",
    rules: { required: "Full Name is required!" }
  },
  {
    label: "Password",
    name: "password",
    type: "password",
    placeholder: "Please enter a password",
    rules: { required: "Password is required!" }
  },
  {
    label: "Re-enter Pasword",
    name: "cpassword",
    type: "password",
    placeholder: "Please re-enter the password",
    rules: { required: "Re-enter Pasword is required!" }
  },
  {
    label: (
      <p className="mb-0 ms-2">
        I agree to the{" "}
        <Link to="/terms-of-service" target="_blank">
          Terms & Conditions{" "}
        </Link>
        and{" "}
        <Link to="/privacy-policy" target="_blank">
          {" "}
          Privacy Policy
        </Link>
      </p>
    ),
    name: "termsOfService",
    type: "checkbox",
    rules: { required: true },
    controlType: "check"
  }
]
