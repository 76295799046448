import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import { CustomCard } from "../../../../components/CustomCard"
import { CustomButton } from "../../../../components/CustomButton"
import { useUserProfileContext } from "../../../../contexts/UserProfileContext"
import { CustomModal } from "../../../../components/CustomModal"
import { assets } from "../../../../utils"
import { useAuthContext } from "../../../../contexts/AuthContext"
import { SubscriptionPopup } from "./subscriptionPopup"
import { Chip } from "../../../../components/Chip"

export const SubscriptionDetails = () => {
  const nav = useNavigate()
  const {
    userProfile,
    isLoading,
    unsubscribePlan,
    getAmountToSubscribe,
    addSubscriptionRequest,
    getPaymentProfiles,
    getLoginUserProfile
  } = useUserProfileContext()
  const { userLogout, notifySuccess } = useAuthContext()
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [showSubscription, setShowSubscription] = useState(false)
  const [showAmount, setShowAmount] = useState(false)
  const [amount, setAmount] = useState(0)
  const [newPlanId, setNewPlanId] = useState(null)
  const [showRemoveCard, setShowRemoveCard] = useState(false)
  const onHide = () => setShow(false)
  const onHideSubscription = () => setShowSubscription(false)
  const onHideAmount = () => setShowAmount(false)
  const onHideRemoveCard = () => setShowRemoveCard(false)
  const { next_transaction_date, tier, status, auto_deduct } =
    userProfile?.current_subscription_plan || {
      next_transaction_date: "",
      tier: {}
    }

  const onClickConfirm = () => {
    const id = userProfile?.current_subscription_plan?.id
    unsubscribePlan(id).then(() => {
      setShow(false)
      getLoginUserProfile()
    })
  }

  const onClickSubscription = id => {
    const config = { tier: id }
    getAmountToSubscribe(config).then(response => {
      const { charge_response } = response
      if (charge_response?.confirmation_url) {
        window.location.href = charge_response.confirmation_url
      }
      setShowSubscription(false)
      // setShowAmount(true)
      // setNewPlanId(id)
      // setAmount(response.amount)
    })
  }

  return (
    <CustomCard title="Subscription Details" className="subscriptionDetails">
      <Row className="align-items-center">
        <Col xs="8">
          <h6 className="title">Current Plan</h6>
          <p>{status !== "I" ? tier?.name : "N/A"}</p>
        </Col>
        {status && status === "T" && (
          <Col xs="4">
            <h6 className="title"></h6>
            <Chip
              label="Trial"
              className="active"
              style={{ letterSpacing: "1px", fontWeight: "500" }}
            />
          </Col>
        )}
        <Col xs="12">
          <h6 className="title">Plan Cost</h6>
          {status !== "I" ? (
            <p>{`$${
              tier?.price ? Number(tier.price).toFixed(2) : Number(0).toFixed(2)
            } per month`}</p>
          ) : (
            <p>N/A</p>
          )}
        </Col>
        <Col xs="12">
          <h6 className="title">Plan Details</h6>
          {status !== "I" ? (
            tier?.description
              ?.split(",")
              .map(line => <p className="mb-2">{line}</p>)
          ) : (
            <p>N/A</p>
          )}
          <p className="mb-3"></p>
        </Col>
        <Col xs="12">
          <h6 className="title">Auto Renewal Due</h6>
          {auto_deduct && (
            <p>{status !== "I" ? next_transaction_date : "N/A"}</p>
          )}
        </Col>
      </Row>
      {status !== "I" ? (
        <Row className="justify-content-center mt-3">
          <Col xs="5">
            <CustomButton
              label="Unsubscribe"
              variant="outline-primary"
              type="button"
              loading={loading}
              onClick={() => setShow(true)}
              className={`${auto_deduct ? "visible" : "invisible"}`}
            />
          </Col>
          <Col xs="5">
            <CustomButton
              label="Change"
              variant="primary"
              type="button"
              disabled={status === "T"}
              loading={loading}
              onClick={() => setShowSubscription(true)}
              // className={`${auto_deduct ? "visible" : "invisible"}`}
            />
          </Col>
        </Row>
      ) : (
        <Row className="justify-content-center mt-3">
          <Col xs="5">
            <CustomButton
              label="Add Subscription"
              variant="primary"
              type="button"
              disabled={status === "T"}
              loading={loading}
              onClick={() => setShowSubscription(true)}
            />
          </Col>
        </Row>
      )}
      <CustomModal size="sm" show={show} onHide={onHide}>
        <Row>
          <Col>
            <h6 className="title">
              <img src={assets.ErrorIcon} /> Unsubscribe
            </h6>
            <p className="sub-title">Are you sure you want to unsubscribe ?</p>
          </Col>
        </Row>
        <Row className="add-card-payment justify-content-center">
          <Col xs="5">
            <CustomButton
              label="Cancel"
              variant="outline-primary"
              type="button"
              onClick={onHide}
            />
          </Col>
          <Col xs="5">
            <CustomButton
              label="Confirm"
              variant="primary"
              type="button"
              loading={isLoading}
              onClick={onClickConfirm}
            />
          </Col>
        </Row>
      </CustomModal>
      <SubscriptionPopup
        show={showSubscription}
        onHide={onHideSubscription}
        onClickSubscription={onClickSubscription}
      />
      <CustomModal size="sm" show={showAmount} onHide={onHideAmount}>
        <Row>
          <Col>
            <h6 className="title">
              <img src={assets.ErrorIcon} /> Subscribe
            </h6>
            <p className="sub-title">
              Are you sure you want to subscribe to new plan ?
            </p>
            <p className="sub-title">{`You will be charged $${Number(
              amount
            ).toFixed(2)}`}</p>
          </Col>
        </Row>
        <Row className="add-card-payment justify-content-center">
          <Col xs="5">
            <CustomButton
              label="Cancel"
              variant="outline-primary"
              type="button"
              onClick={onHideAmount}
            />
          </Col>
          <Col xs="5">
            <CustomButton
              label="Confirm"
              variant="primary"
              type="button"
              loading={isLoading}
              onClick={() => {
                addSubscriptionRequest({ tier: newPlanId }).then(() => {
                  setShowAmount(false)
                  setNewPlanId(null)
                  notifySuccess("You subscribed to new plan successfully!")
                  getLoginUserProfile()
                })
              }}
            />
          </Col>
        </Row>
      </CustomModal>
    </CustomCard>
  )
}
