import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import { CustomCard } from "../../components/CustomCard"
import { useAuthContext } from "../../contexts/AuthContext"
import { assets } from "../../utils"
import { CustomButton } from "../../components/CustomButton"
import { CustomContainer } from "../../components/CustomContainer"
import { AccountTabs } from "./accounttabs"
import { FileUpload } from "../../components/FileUpload/FileUpload"
import { useUserProfileContext } from "../../contexts/UserProfileContext"
import "./settings.scss"

export const AccountSettings = () => {
  const nav = useNavigate()
  const { isLoading, userLogout } = useAuthContext()
  const { userProfile, getLoginUserProfile, updateUserProfile } =
    useUserProfileContext()
  const [photo, setPhoto] = useState(null)
  const [loading, setLoading] = useState(false)
  const { email, name, user_profile } = userProfile

  const onChangePhoto = file => {
    setPhoto(file)
  }

  const onClickUpload = () => {
    const payload = {}
    payload.photo = photo
    setLoading(true)
    updateUserProfile(user_profile.id, payload)
      .then(() => {
        setPhoto(null)
        getLoginUserProfile()
      })
      .catch(() => {
        setLoading(false)
        setPhoto(null)
      })
      .finally(() => {
        setLoading(false)
        setPhoto(null)
      })
  }

  return (
    <CustomContainer title="Account Setting">
      <Row className="mb-3">
        <Col>
          <CustomCard className="user-profile">
            <Row>
              <Col
                xs="12"
                lg="4"
                className="d-flex align-items-center user-profile mb-2"
              >
                <img
                  src={user_profile?.photo || assets.UserIcon}
                  style={{
                    width: "6rem",
                    height: "6rem",
                    borderRadius: "50%",
                    objectFit: "cover"
                  }}
                />
                <div>
                  <FileUpload onChange={onChangePhoto} />
                  <h5 className="mb-1 ms-2 user-name">{`${name || ""}`}</h5>
                  <p className="mb-0 ms-2 user-email">{`${email || ""}`}</p>
                </div>
              </Col>
              <Col xs="12" lg="2" className="d-flex align-items-center ms-auto">
                {photo ? (
                  <CustomButton
                    label="Click upload"
                    type="button"
                    loading={loading}
                    onClick={onClickUpload}
                  />
                ) : (
                  <CustomButton
                    label="Change Password"
                    variant="outline-primary"
                    type="button"
                    onClick={() => nav("/change-password")}
                  />
                )}
              </Col>
            </Row>
          </CustomCard>
        </Col>
      </Row>
      <Row className="account-tabs">
        <Col>
          <AccountTabs />
        </Col>
      </Row>
    </CustomContainer>
  )
}
