import React, { useEffect } from "react"
import { CustomModal } from "../../../../components/CustomModal"
import { useUserProfileContext } from "../../../../contexts/UserProfileContext"
import { Subscription } from "../../../subscriptions/subscription"
import { CustomSpinner } from "../../../../components/CustomSpinner"
import { Col, Container, Row } from "react-bootstrap"

export const SubscriptionPopup = ({ show, onHide, onClickSubscription }) => {
  const {
    isLoading,
    subscriptions,
    getSubscriptions,
    currentSubscription,
    getCurrentSubscription
  } = useUserProfileContext()

  useEffect(() => {
    if (show) {
      getSubscriptions()
      getCurrentSubscription()
    }
  }, [show])

  return (
    <CustomModal showHeader={true} show={show} onHide={onHide}>
      <Container className="mt-3">
        <Row>
          {subscriptions.map((subscription, index) => (
            <Col xs={12} lg={3} className="mb-4" key={index}>
              <Subscription
                {...subscription}
                name={subscription.name}
                onClickSubscription={onClickSubscription}
                currentPlanId={currentSubscription?.[0]?.tier?.id}
                status={currentSubscription?.[0]?.status}
                mt="mt-5"
              />
            </Col>
          ))}
        </Row>
      </Container>
      <CustomSpinner loading={isLoading} />
    </CustomModal>
  )
}
