import { Col, Container, Row } from "react-bootstrap"
import { CustomCard } from "../components/CustomCard"
import { Subscription } from "./subscriptions/subscription"

export const StoryBook = () => (
  <div className="d-flex align-items-center justify-content-center min-vh-100">
    <CustomCard
      title="Our Plans"
      subTitle="We offer a variety subscription plans to meet your needs."
      className="custom"
    >
      <Row style={{ gap: "1.5rem" }}>
        {[1, 2, 3].map(() => (
          <Col>
            <Subscription />
          </Col>
        ))}
      </Row>
    </CustomCard>
  </div>
)
