import { useEffect, useState } from "react"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import Offcanvas from "react-bootstrap/Offcanvas"
import { useLocation, useNavigate } from "react-router-dom"
import { useAuthContext } from "../../contexts/AuthContext"
import { useUserProfileContext } from "../../contexts/UserProfileContext"
import { SUPPORT_URL, assets } from "../../utils"
import { useMyProductsContext } from "../../contexts/MyProductsContext"
import { useDashboardContext } from "../../contexts/DashboardContext"
import "./Navbars.scss"

export function NavbarOffcanvas({ navigations = [] }) {
  const nav = useNavigate()
  const location = useLocation()
  const expand = "md"
  const { user, getUser, userLogout } = useAuthContext()
  const { userProfile } = useUserProfileContext()
  const { setFilterParams, setShowResetPriceUpdates } = useMyProductsContext()
  const { setPrieUpdatesActive } = useDashboardContext()
  const [navExpanded, setNavExpanded] = useState(false)
  const { user_profile } = userProfile

  useEffect(() => {
    getUser()
  }, [])

  return (
    <>
      <Navbar
        collapseOnSelect
        expanded={navExpanded}
        onToggle={() => setNavExpanded(!navExpanded)}
        key={expand}
        expand={expand}
        className="bg-body-tertiary"
      >
        <Container fluid>
          <Navbar.Brand
            style={{ cursor: "pointer" }}
            onClick={() => nav("/dashboard")}
          >
            <img src={assets.LogoImg} />
            <img src={assets.SmokeSourceImg} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="start"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                Offcanvas
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav
                className="me-auto"
                onClick={() => setNavExpanded(false)}
                activeKey={location.pathname}
              >
                {navigations.map(({ label, path, icon, iconActive }) => (
                  <Nav.Link
                    key={label}
                    onClick={() => {
                      if (path.includes("support")) {
                        return window.open(SUPPORT_URL)
                      }
                      if (path.includes("my-products")) {
                        setFilterParams(prevFilters => ({
                          ...prevFilters,
                          price_updates: false,
                          my_products: true,
                          inStock: true,
                          outOfStock: true
                        }))
                        setPrieUpdatesActive(false)
                        setShowResetPriceUpdates(false)
                      }
                      nav(path)
                    }}
                    className={`d-flex align-items-center ms-4 ${
                      location.pathname === path ? "active" : ""
                    }`}
                  >
                    <img src={location.pathname === path ? iconActive : icon} />
                    <span className="nav-text ms-3">{label}</span>
                  </Nav.Link>
                ))}
              </Nav>
              <NavDropdown
                title={
                  <div className="d-flex align-items-center user-profile">
                    <img
                      src={user_profile?.photo || assets.UserIcon}
                      style={{
                        width: "2rem",
                        height: "2rem",
                        borderRadius: "50%",
                        objectFit: "cover"
                      }}
                    />
                    <div>
                      <p className="mb-0 ms-2">Welcome</p>
                      <p className="mb-0 ms-2 user-name">{`${
                        user_profile?.name || user?.name
                      }`}</p>
                    </div>
                  </div>
                }
                id="collasible-nav-dropdown"
              >
                {/* <NavDropdown.Item onClick={() => nav("/change-password")}>
                  Change Password
                </NavDropdown.Item>
                <NavDropdown.Divider /> */}
                <NavDropdown.Item onClick={() => nav("/settings")}>
                  Settings
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  onClick={() => {
                    userLogout().then(() => {
                      nav("/login")
                    })
                  }}
                >
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  )
}
