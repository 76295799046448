import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { CustomCard } from "../../../../components/CustomCard"
import { CreditCard } from "../../../../components/CreditCard"
import { CustomButton } from "../../../../components/CustomButton"
import { CustomModal } from "../../../../components/CustomModal"
import { PaymentForm } from "../../../../components/PaymentForm"
import { useAuthContext } from "../../../../contexts/AuthContext"
import { assets, formatDateForDB } from "../../../../utils"
import { useUserProfileContext } from "../../../../contexts/UserProfileContext"
import { useEffect } from "react"
import { CustomSpinner } from "../../../../components/CustomSpinner"

export const PaymentDetails = () => {
  const { notifySuccess, confirmPayment } = useAuthContext()
  const {
    isLoading,
    paymentProfiles,
    getPaymentProfiles,
    addPaymentProfile,
    deletePaymentProfile,
    makePaymentDefault,
    setPaymentProfiles
  } = useUserProfileContext()
  const [show, setShow] = useState(false)
  const [showRemoveCard, setShowRemoveCard] = useState(false)
  const onHide = () => setShow(false)
  const onHideRemoveCard = () => setShowRemoveCard(false)
  const [idToRemove, setIdToRemove] = useState(null)
  const [loading, setLoading] = useState(false)

  const onSubmit = async vals => {
    const cardData = { cardNumber: "", month: "", year: "", cardCode: "" }
    const { card, expDate, cvv } = vals
    const { year, month } = formatDateForDB(expDate)
    cardData.cardNumber = card
    cardData.year = year
    cardData.month = month
    cardData.cardCode = cvv
    setLoading(true)
    confirmPayment(cardData)
      .then(response => {
        const { messages, opaqueData } = response
        if (messages.resultCode === "Ok") {
          console.log("opaqueData", opaqueData)
          addPaymentProfile(opaqueData).then(() => {
            setShow(false)
            getPaymentProfiles()
            setLoading(false)
            notifySuccess("Card added successfully.")
          })
        }
      })
      .then(() => setLoading(false))
  }

  const onClickRemove = () => {
    deletePaymentProfile(idToRemove).then(() => {
      onHideRemoveCard()
      notifySuccess("Card removed successfully.")
      getPaymentProfiles()
    })
  }

  const onClickDefault = id => {
    makePaymentDefault(id).then(() => {
      const update = paymentProfiles.map(item => {
        if (item.id === id) {
          return { ...item, is_default: true }
        }
        return { ...item, is_default: false }
      })
      setPaymentProfiles(update)
    })
  }

  useEffect(() => {
    getPaymentProfiles()
  }, [])

  return (
    <CustomCard title="" className="paymentDetails">
      <Row className="card-lists align-items-center flex-nowrap">
        {paymentProfiles.map((paymentProfile, index) => (
          <Col xs="12" lg="5" className="mb-3 ms-3" key={index}>
            <CreditCard
              {...paymentProfile}
              isLoading={isLoading}
              onClickRemove={id => {
                setIdToRemove(id)
                setShowRemoveCard(true)
              }}
              onClickDefault={onClickDefault}
            />
          </Col>
        ))}
      </Row>
      <Row>
        <Col xs="12">
          <CustomButton
            label="Add"
            variant="primary"
            type="button"
            className="ms-auto add-credit-card-btn"
            onClick={() => setShow(true)}
          />
        </Col>
      </Row>
      <CustomModal size="md" show={show} onHide={onHide}>
        <Row>
          <Col>
            <h5 className="title">Add a Card</h5>
            <p className="sub-title">Please enter your card details</p>
          </Col>
        </Row>
        <PaymentForm
          addCard={true}
          isLoading={loading}
          onCancel={onHide}
          onSubmit={onSubmit}
        />
      </CustomModal>
      <CustomModal size="sm" show={showRemoveCard} onHide={onHideRemoveCard}>
        <Row>
          <Col>
            <h6 className="title">
              <img src={assets.ErrorIcon} /> Remove Card
            </h6>
            <p className="sub-title">Are you sure want remove this card ?</p>
          </Col>
        </Row>
        <Row className="add-card-payment justify-content-center">
          <Col xs="5">
            <CustomButton
              label="Cancel"
              variant="outline-primary"
              type="button"
              onClick={onHideRemoveCard}
            />
          </Col>
          <Col xs="5">
            <CustomButton
              label="Remove"
              variant="primary"
              type="button"
              loading={isLoading}
              onClick={onClickRemove}
            />
          </Col>
        </Row>
      </CustomModal>
      <CustomSpinner loading={loading} />
    </CustomCard>
  )
}
