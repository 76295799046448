import React, { createContext, useContext, useState } from "react"
import { toast } from "react-toastify"
import { API } from "../api"
import { useAcceptJs } from "react-acceptjs"

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [authToken, setAuthToken] = useState("")
  const [user, setUser] = useState({})
  const [show, setShow] = useState(false)
  const [privacyPolicy, setPrivacyPolicy] = useState("")
  const [terms, setTerms] = useState("")
  const [config, setConfig] = useState({
    REACT_APP_AUTHORIZE_CLIENT_KEY: "",
    REACT_APP_AUTHORIZE_MERCHANT_NAME: "",
    REACT_APP_AUTHORIZE_ENVIRONMENT: ""
  })

  const authData = {
    apiLoginID: config.REACT_APP_AUTHORIZE_MERCHANT_NAME,
    clientKey: config.REACT_APP_AUTHORIZE_CLIENT_KEY
  }
  const { dispatchData } = useAcceptJs({ authData, environment: config?.REACT_APP_AUTHORIZE_ENVIRONMENT })
  const notifySuccess = successMessage => toast.success(successMessage)

  const userSignup = payload => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.signup(payload)
        .then(response => {
          const { token, user } = response.data.data
          localStorage.setItem("token", token)
          localStorage.setItem("user", JSON.stringify(user))
          setAuthToken(token)
          resolve(user)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const signWithShopify = config => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.shopifySignup(config)
        .then(response => {
          const { token, user } = response.data.data
          localStorage.setItem("token", token)
          localStorage.setItem("user", JSON.stringify(user))
          setAuthToken(token)
          resolve(user)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const confirmPayment = cardData => {
    // setIsLoading(true)
    return new Promise(async (resolve, reject) => {
      dispatchData({ cardData })
        .then(response => resolve(response))
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const userLogin = payload => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.login(payload)
        .then(response => {
          const { token, user } = response.data.data
          localStorage.setItem("token", token)
          localStorage.setItem("user", JSON.stringify(user))
          setAuthToken(token)
          resolve(user)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const loginWithShopify = config => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.shopifyLogin(config)
        .then(response => {
          if (response.data.data?.redirect_url) {
            return (window.location.href = response.data.data.redirect_url)
          }
          const { token, user } = response.data.data
          localStorage.setItem("token", token)
          localStorage.setItem("user", JSON.stringify(user))
          setAuthToken(token)
          resolve(user)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const userLogout = () => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.logout()
        .then(response => {
          resolve(response)
          setAuthToken("")
        })
        .catch(error => console.log(error))
        .finally(() => {
          localStorage.clear()
          localStorage.removeItem("token")
          localStorage.removeItem("user")
          setIsLoading(false)
          resolve()
        })
    })
  }

  const forgetPassword = payload => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.forgetPassword(payload)
        .then(response => {
          const { status_code, data } = response.data
          if (status_code == 200) {
            resolve(data)
          }
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const resetPasswordConfirm = payload => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.resetPasswordConfirm(payload)
        .then(response => {
          console.log(response.data)
          const { status_code, data } = response.data
          if (status_code == 200) {
            resolve(data)
          }
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const passwordChange = payload => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.passwordChange(payload)
        .then(response => {
          resolve(response.data.payload)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getUser = () => {
    const user = JSON.parse(localStorage.getItem("user"))
    if (user?.email) {
      setUser(user)
    }
  }

  const getUserProfile = () => {
    API.getUserProfile()
      .then(response => {
        console.log("response.data.data", response.data)
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const getPrivacyPolicy = () => {
    setIsLoading(true)
    API.getPrivacyPolicy()
      .then(response => setPrivacyPolicy(response.data.data.results[0].body))
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const getTerms = () => {
    setIsLoading(true)
    API.getTerms()
      .then(response => setTerms(response.data.data.results[0].body))
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const getAppUrlToInstall = config => {
    // setIsLoading(true)
    return new Promise(resolve => {
      API.getAppUrlToInstall(config)
        .then(response => {
          resolve(response.data.data.redirect_url)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getConfig = identifier => {
    setIsLoading(true)
    API.getConfig(identifier)
      .then(response => {
        if (response.data.status_code === 200) {
          setConfig(response.data.data)
        }
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  useState(() => {
    const token = localStorage.getItem("token")
    if (token) {
      setAuthToken(token)
    }
    getConfig()
  }, [])

  console.log("CONFIG VALUES", config)
  return (
    <AuthContext.Provider
      value={{
        isLoading,
        notifySuccess,
        userLogin,
        userLogout,
        userSignup,
        forgetPassword,
        resetPasswordConfirm,
        passwordChange,
        user,
        getUser,
        authToken,
        setAuthToken,
        show,
        setShow,
        loginWithShopify,
        signWithShopify,
        confirmPayment,
        privacyPolicy,
        getPrivacyPolicy,
        terms,
        getTerms,
        getAppUrlToInstall
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)

export default AuthProvider
