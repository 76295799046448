import React from "react"
import { assets } from "../../utils"
import { Col, Row } from "react-bootstrap"
import { Chip } from "../../components/Chip"

export const MyProductVariants = ({
  variants,
  onClickHandle,
  onClickAddHandle,
  onClickRemoveHandle
}) => {
  const isOnePresentInStore =
    variants?.filter(({ if_present_in_store }) => if_present_in_store)
      .length === 1
      ? true
      : false
  return (
    <Row className="mb-3">
      {variants?.map(
        ({ id, if_present_in_store, title, in_stock, checked, is_deleted }) =>
          (!if_present_in_store && is_deleted) ||
          (!if_present_in_store && !in_stock) ? null : (
            <Col xs="6" md="6" lg="4" xl="4" xxl="3" className="d-flex mb-2">
              <Chip
                label={title}
                className={`${!in_stock ? "out-of-stock" : ""} ${
                  checked ? "active" : ""
                }`}
                onClick={() => {
                  return onClickHandle(id)
                }}
              />
              {if_present_in_store ? (
                <img
                  src={assets.DeleteIcon}
                  style={{ cursor: "pointer" }}
                  className={isOnePresentInStore ? "disabled-img-icon" : ""}
                  onClick={() => {
                    if (!isOnePresentInStore) {
                      onClickRemoveHandle(id)
                    }
                  }}
                />
              ) : (
                <img
                  src={assets.AddCircleIcon}
                  style={{ cursor: "pointer" }}
                  className={!in_stock ? "disabled-img-icon" : ""}
                  onClick={() => {
                    if (in_stock) {
                      onClickAddHandle(id)
                    }
                  }}
                />
              )}
            </Col>
          )
      )}
    </Row>
  )
}
