import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Col, Container, Row } from "react-bootstrap"
import { assets, stringToHTML } from "../../utils"
import { CustomButton } from "../../components/CustomButton"
import { useBrowseProductsContext } from "../../contexts/BrowseProductsContext"
import { useParams } from "react-router-dom"
import { useEffect } from "react"
import { CustomSlider } from "../../components/CustomSlider"
import { CustomSpinner } from "../../components/CustomSpinner"
import { Variants } from "./variants"
import { PriceListPopup } from "./priceListPopup"
import { useAuthContext } from "../../contexts/AuthContext"
import { ConfirmPopup } from "../DashboardContainer/browseproducts/confirmPopup"
import { EditPrice } from "./editPrice"
import { MyProductVariants } from "./myProductVariants"
import { useMyProductsContext } from "../../contexts/MyProductsContext"
import "./productdetails.scss"

export const ProductDetails = ({ my_products }) => {
  const [show, setShow] = useState(false)
  const onHide = () => setShow(false)
  const params = useParams()
  const nav = useNavigate()
  const location = useLocation()
  const {
    isLoading,
    product,
    getBrowseProduct,
    addProductToMyStore,
    removeProductFromMyStore,
    setProduct,
    updateSellingPrice
  } = useBrowseProductsContext()
  const { notifySuccess } = useAuthContext()
  const {
    isLoading: variantLoading,
    addVariant,
    removeVariant
  } = useMyProductsContext()
  const { body_html, title, vendor, if_present_in_user_store } = product
  const [selectedVariant, setSelectedVariant] = useState({})
  const [catalogVariantSelected, setCatalogVariantSelected] = useState({})
  const [idToRemove, setIdToRemove] = useState(null)
  const [showConfirm, setShowConfirm] = useState(false)
  const onHideConfirm = () => {
    setIdToRemove(null)
    setShowConfirm(false)
  }

  const [editPrice, setEditPrice] = useState(false)
  const [variants, setVariants] = useState([])
  const [variantToAdd, setVariantToAdd] = useState(null)
  const [showVariant, setShowVariant] = useState(false)

  const onClickHandle = id => {
    const update = variants.map(item => ({ ...item, checked: false }))
    const index = variants.findIndex(item => item.id === id)
    update[index].checked = true
    setVariants(update)
  }

  const onSubmit = vals => {
    const { my_store_product_variants } = vals
    const payload = []
    vals.product = product.id
    vals.my_store_product_variants = my_store_product_variants
      .filter(({ checked }) => checked)
      .map(({ id, price }) => ({
        variant: id,
        price
      }))

    payload.push(vals)
    addProductToMyStore(payload).then(() => {
      getBrowseProduct(product.id)
      setShow(false)
      notifySuccess("Products will be added to your store after sometime.")
    })
  }

  const onSubmitSellingPrice = vals => {
    const id = variantSelected.id
    updateSellingPrice(id, vals).then(() => {
      const update = { ...product }
      const index = update.product_catalog_variants.findIndex(
        ({ id }) => id == variantSelected.id
      )
      update.product_catalog_variants[index].store_selling_price = vals.price
      update.product_catalog_variants[index].checked = true
      setProduct(update)
      setEditPrice(false)
      setVariants(update.product_catalog_variants)
      notifySuccess("Price updated successfully!")
    })
  }

  useEffect(() => {
    if (params?.id) {
      const { id } = params
      getBrowseProduct(id)
    }
  }, [params])

  useEffect(() => {
    if (product?.id) {
      setCatalogVariantSelected(product.product_catalog_variants[0])
    }

    return () => {
      setCatalogVariantSelected({})
      setSelectedVariant({})
    }
  }, [product.id])

  useEffect(() => {
    if (product?.id && Object.keys(selectedVariant).length > 0) {
      const itemFound = product.product_catalog_variants.find(
        ({ option1, option2, option3 }) =>
          `${option1}${option2}${option3}` ===
          Object.values(selectedVariant).join("")
      )
      if (!itemFound) {
        setCatalogVariantSelected(prevState => ({
          ...prevState,
          price: null,
          inventory_quantity: 0
        }))
      } else {
        setCatalogVariantSelected(itemFound)
      }
    }
  }, [product, selectedVariant])

  useEffect(() => {
    if (location?.state?.price_updates) {
      return setVariants(
        product.product_catalog_variants?.map(item => {
          if (item.price_update_info.is_price_updated) {
            return { ...item, checked: true }
          }
          return item
        })
      )
    }
    setVariants(
      product.product_catalog_variants?.map((item, index) => {
        if (index === 0) {
          return { ...item, checked: true }
        }
        return item
      })
    )
  }, [product.product_catalog_variants])

  const variantSelected = variants?.find(({ checked }) => checked)

  return (
    <Container className="product-details-container" fluid>
      <Row className="p-3">
        <Col xs="12" md="6"></Col>
        <Col
          xs="12"
          md="6"
          className="d-flex align-items-center"
          onClick={() => {
            if (location.state?.redirectUrl) {
              return nav(location.state.redirectUrl)
            } else if (my_products) {
              return nav("/my-products")
            }
            // nav("/browse-products")
            nav(-1)
          }}
          style={{ cursor: "pointer" }}
        >
          <img src={assets.BackArrowIcon} />
          <h5 className="page-title mb-0 ms-3">Product Details</h5>
        </Col>
      </Row>
      <Row className="p-3">
        <Col xs="12" md="6" className="left-section mb-5">
          {product?.id && (
            <CustomSlider slides={[...product.product_catalog_images]} />
          )}
        </Col>
        <Col xs="12" md="6" className="right-section ps-3">
          <Row>
            <Col xs="8" lg="9" xl="10">
              <h6 className="title">{title} </h6>
            </Col>
            {my_products ? (
              <Col xs="4" lg="3" xl="2" className="text-center ps-1 pe-1">
                {variantSelected?.id && (
                  <p className="quantity mb-2">{`${variantSelected?.inventory_quantity} in stock`}</p>
                )}
              </Col>
            ) : (
              <Col xs="4" lg="3" xl="2" className="text-center ps-1 pe-1">
                {catalogVariantSelected?.id && (
                  <p className="quantity mb-2">{`${catalogVariantSelected?.inventory_quantity} in stock`}</p>
                )}
              </Col>
            )}
            <Col xs="12">
              <h6 className="brand">{vendor}</h6>
            </Col>
            {my_products ? (
              <MyProductVariants
                variants={variants}
                onClickHandle={onClickHandle}
                onClickAddHandle={id => {
                  setVariantToAdd(id)
                  setShowVariant(true)
                }}
                onClickRemoveHandle={id => {
                  setIdToRemove(id)
                  setTimeout(() => setShowConfirm(true), 0)
                }}
              />
            ) : (
              <Col xs="12" className="variants-wrapper">
                {product?.product_catalog_options?.length > 0 && (
                  <Variants
                    product_catalog_options={[
                      ...product.product_catalog_options
                    ]}
                    catalogVariantSelected={{ ...catalogVariantSelected }}
                    setSelectedVariant={setSelectedVariant}
                  />
                )}
              </Col>
            )}

            {my_products && variantSelected?.in_stock && (
              <Col xs="12" lg="12" className="d-flex align-items-start mb-0">
                {variantSelected?.store_selling_price && !editPrice ? (
                  <>
                    <h5 className="cost-price">
                      Selling Price:
                      <span
                        style={{ color: "#27963C" }}
                      >{` $${variantSelected.store_selling_price}`}</span>
                    </h5>
                    <img
                      src={assets.EditIcon}
                      className="ms-3"
                      style={{ cursor: "pointer" }}
                      onClick={() => setEditPrice(true)}
                    />
                  </>
                ) : (
                  editPrice && (
                    <EditPrice
                      key={variantSelected?.id}
                      value={variantSelected.store_selling_price}
                      MAP={
                        variantSelected?.variant_metafields.find(
                          ({ name }) => name === "MAP"
                        )?.value || 0
                      }
                      MSRP={
                        variantSelected?.variant_metafields.find(
                          ({ name }) => name === "MSRP"
                        )?.value || Number.POSITIVE_INFINITY
                      }
                      onSubmit={onSubmitSellingPrice}
                      onClickCancel={() => setEditPrice(false)}
                      isLoading={isLoading}
                    />
                  )
                )}
              </Col>
            )}
            {my_products ? (
              <Col xs="12" lg="8" xl="8" xxl="6" className="mb-2 d-flex">
                <h6 className="cost-price">
                  {`Cost Price: $${
                    variantSelected?.price ? variantSelected?.cost : "N/A"
                  }*`}
                </h6>
                {variantSelected?.price_update_info?.is_price_updated && (
                  <h6 className="ms-auto">
                    {`Old Cost Price: $${variantSelected?.price_update_info?.old_price}*`}
                  </h6>
                )}
              </Col>
            ) : (
              <>
              <Col xs="6" lg="4" className="mb-2">
                <h6 className="cost-price">
                  {`Cost Price: $${
                    catalogVariantSelected?.cost
                      ? catalogVariantSelected?.cost
                      : "N/A"
                  }*`}
                </h6>
              </Col>
              <Col xs="6" lg="4" className="mb-2">
                <h6 className="cost-price">
                  {`Selling Price: $${
                    catalogVariantSelected?.price
                      ? catalogVariantSelected?.price
                      : "N/A"
                  }*`}
                </h6>
              </Col>
              </>
            )}
            <Row>
              <Col xs="6" md="6" lg="4" className="mb-3">
                {if_present_in_user_store ? (
                  <CustomButton
                    label="Remove from My Products"
                    variant="outline-primary"
                    type="button"
                    disabled={isLoading}
                    onClick={() => {
                      setIdToRemove(product.id)
                      setTimeout(() => setShowConfirm(true), 0)
                    }}
                  />
                ) : (
                  <CustomButton
                    label="Add to My Products"
                    type="button"
                    disabled={isLoading}
                    onClick={() => setShow(true)}
                  />
                )}
              </Col>
            </Row>
            <Col xs="12">
              {body_html && (
                <p className="content">{stringToHTML(body_html)}</p>
              )}
            </Col>
          </Row>
          {product?.product_catalog_variants?.length > 0 && (
            <PriceListPopup
              key={product.id}
              show={show}
              onHide={onHide}
              data={product.product_catalog_variants
                ?.filter(({ in_stock }) => in_stock)
                .map(item => ({
                  ...item,
                  checked: true
                }))}
              loading={isLoading}
              onSubmit={onSubmit}
            />
          )}
          {variantToAdd && product?.product_catalog_variants?.length > 0 && (
            <PriceListPopup
              key={variantToAdd}
              show={showVariant}
              onHide={() => {
                setVariantToAdd(null)
                setShowVariant(false)
              }}
              data={[...product.product_catalog_variants]
                .filter(item => item.id === variantToAdd)
                .map(item => ({ ...item, checked: true }))}
              loading={isLoading || variantLoading}
              onSubmit={vals => {
                const { id, price } = vals.my_store_product_variants[0]
                const payload = {}
                payload.variant = id
                payload.price = price
                addVariant(payload).then(() => {
                  const update = [...variants]
                  const index = update.findIndex(({ id }) => id == variantToAdd)
                  update[index].store_selling_price = price
                  update[index].if_present_in_store = true
                  setVariants(update)
                  setVariantToAdd(null)
                  setShowVariant(false)
                  notifySuccess("The variant added successfully!")
                })
              }}
            />
          )}
        </Col>
      </Row>
      <ConfirmPopup
        title="Remove Product"
        subTitle="Are you sure want to remove this product ?"
        key={idToRemove}
        show={showConfirm}
        onHide={onHideConfirm}
        isLoading={isLoading || variantLoading}
        btnTitle="Remove"
        onClick={() => {
          if (Number(params.id) === idToRemove) {
            return removeProductFromMyStore(idToRemove).then(() => {
              setIdToRemove(null)
              setShowConfirm(false)
              getBrowseProduct(params.id)
              notifySuccess("Product removed successfully!")
            })
          }
          removeVariant(idToRemove).then(() => {
            const update = [...variants]
            const index = update.findIndex(({ id }) => id == idToRemove)
            update[index].store_selling_price = null
            update[index].if_present_in_store = false
            setVariants(update)
            setIdToRemove(null)
            setShowConfirm(false)
            notifySuccess("Product removed successfully!")
          })
        }}
      />
      <CustomSpinner loading={isLoading || variantLoading} />
    </Container>
  )
}
