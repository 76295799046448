import React, { useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { CustomCard } from "../../../components/CustomCard"
import { useForm } from "react-hook-form"
import { Col, Row } from "react-bootstrap"
import { assets } from "../../../utils"
import { CustomSelect } from "../../../components/CustomSelect"
import { useUserProfileContext } from "../../../contexts/UserProfileContext"
import { CustomButton } from "../../../components/CustomButton"

export const TimeZone = ({ user_profile, timeZones }) => {
  const { isLoading, userProfile, updateUserProfile } = useUserProfileContext()
  const formSchema = Yup.object().shape({
    timezone: Yup.object().shape({
      label: Yup.string().required("Required"),
      value: Yup.string().required("Required")
    })
  })
  const formOptions = { resolver: yupResolver(formSchema) }
  const { handleSubmit, control, trigger, setValue } = useForm(formOptions)
  const [editMode, setEditMode] = useState(false)
  const [timezone, setTimeZone] = useState({})

  const onSubmit = vals => {
    const id = userProfile?.user_profile?.id
    const { timezone } = vals
    updateUserProfile(id, { timezone: timezone.value }).then(() => {
      setEditMode(false)
    })
  }

  useState(() => {
    if (user_profile?.timezone) {
      const timezoneFound = timeZones.find(
        ({ value }) => value == user_profile.timezone
      )
      setTimeZone(timezoneFound)
      setValue("timezone", timezoneFound)
    }
  }, [user_profile])

  return (
    <CustomCard
      title="Time Zone"
      icon={
        <img
          src={assets.EditIcon}
          style={{ cursor: "pointer" }}
          onClick={() => setEditMode(true)}
        />
      }
      className="contact-info"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <CustomSelect
            label="Zone"
            name="timezone"
            options={timeZones}
            value={timezone}
            onChange={option => {
              setValue("timezone", option)
              setTimeZone(option)
            }}
            placeholder={"Select Timezone"}
            isDisabled={!editMode}
            control={control}
            trigger={trigger}
          />
        </Row>
        {editMode && (
          <Row className="justify-content-end mt-5">
            <Col xs="4">
              <CustomButton
                label="Cancel"
                variant="outline-primary"
                type="button"
                onClick={() => {
                  const timezoneFound = timeZones.find(
                    ({ value }) => value == user_profile.timezone
                  )
                  if (timezoneFound) {
                    setTimeZone(timezoneFound)
                  } else {
                    setTimeZone({})
                  }
                  setValue("timezone", timezoneFound)
                  setEditMode(false)
                }}
              />
            </Col>
            <Col xs="4">
              <CustomButton
                label="Save"
                variant="primary"
                type="submit"
                loading={isLoading}
              />
            </Col>
          </Row>
        )}
      </form>
    </CustomCard>
  )
}
