import React from "react"
import { Col, Row } from "react-bootstrap"
import { CustomModal } from "../../../components/CustomModal"
import { CustomButton } from "../../../components/CustomButton"
import { assets } from "../../../utils"

export const ConfirmPopup = ({
  title,
  subTitle,
  show,
  onHide,
  isLoading,
  btnTitle,
  onClick
}) => {
  return (
    <CustomModal size="sm" show={show} onHide={onHide}>
      <Row>
        <Col>
          <h6 className="title">
            <img src={assets.ErrorIcon} /> {title}
          </h6>
          <p className="sub-title text-nowrap">{subTitle}</p>
        </Col>
      </Row>
      <Row className="add-card-payment justify-content-center">
        <Col xs="5">
          <CustomButton
            label="Cancel"
            variant="outline-primary"
            type="button"
            onClick={onHide}
          />
        </Col>
        <Col xs="5">
          <CustomButton
            label={btnTitle}
            variant="primary"
            type="button"
            loading={isLoading}
            onClick={onClick}
          />
        </Col>
      </Row>
    </CustomModal>
  )
}
