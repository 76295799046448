import React from "react"
import { Button, Row } from "react-bootstrap"
import { CustomAgGridTable } from "../../components/CustomAgGridTable"

export const UserList = ({ users }) => {
  const columns = [
    {
      field: "name",
      headerName: "User Name",
      minWidth: 120,
      flex: 1
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      flex: 1
    },
    {
      field: "user_store",
      headerName: "Store Url",
      minWidth: 275,
      flex: 1,
      cellRenderer: ({ value }) => {
        console.log(value)
        if (value?.url) return `${value.url}`
      }
    },

    {
      field: "id",
      headerName: "Actions",
      minWidth: 100,
      maxWidth: 150,
      cellRenderer: ({ value }) => (
        <Button
          variant="link"
          onClick={() => {
            localStorage.setItem("xUserAccount", value)
            const { origin } = window.location
            window.open(`${origin}/dashboard`)
          }}
        >
          Log In
        </Button>
      )
    }
  ]

  return (
    <Row className="p-1 mb-3">
      <CustomAgGridTable
        rowData={users}
        columnDefs={columns}
        pagination={true}
        paginationPageSize={10}
        height="60vh"
      />
    </Row>
  )
}
