import React from "react"
import { Form } from "react-bootstrap"
import { assets } from "../../utils"
import "./fileUpload.scss"

export const FileUpload = ({ onChange, error }) => {
  return (
    <Form.Group
      className="fileUploadContainer mb-3"
      controlId="fileUpload.ControlInput"
    >
      <Form.Control
        type="file"
        className="fileUploadField"
        onChange={e => onChange(e.target.files[0])}
      />
      <div className="logoContainer">
        <img src={assets.UploadImg} />
      </div>
      {error && (
        <Form.Control.Feedback className="d-block" type="invalid">
          {error?.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}
