import React, { useEffect } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { Col, Row } from "react-bootstrap"
import { InputField } from "../../components/InputField"
import { CustomButton } from "../../components/CustomButton"

export const EditPrice = ({
  value,
  MAP,
  MSRP,
  onSubmit,
  onClickCancel,
  isLoading
}) => {
  const formSchema = Yup.object().shape({
    price: Yup.number()
      .min(MAP)
      .max(MSRP)
      .typeError("Invalid price")
      .required("Selling price is required")
  })
  const formOptions = { resolver: yupResolver(formSchema) }
  const { handleSubmit, control, trigger, reset, setValue } =
    useForm(formOptions)

  useEffect(() => {
    setValue("price", value)
  }, [value])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col xs="12" lg="6">
          <InputField
            label=""
            name="price"
            placeholder="Selling price"
            control={control}
            trigger={trigger}
            defaultValue={value}
          />
        </Col>
        <Col xs="6" lg="3">
          <CustomButton
            label="Cancel"
            variant="outline-primary"
            type="button"
            className="mt-4"
            disabled={isLoading}
            onClick={() => {
              reset({
                price: ""
              })
              onClickCancel()
            }}
          />
        </Col>
        <Col xs="6" lg="3">
          <CustomButton
            label="Save"
            variant="primary"
            type="submit"
            className="mt-4"
            disabled={isLoading}
            loading={isLoading}
          />
        </Col>
      </Row>
    </form>
  )
}
