import React, { useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import { CustomContainer } from "../../../components/CustomContainer"
import { PaymentPendingCard } from "./PaymentPendingCard"
import { OpenOrdersCard } from "./OpenOrdersCard"
import { ProductAlert } from "./ProductAlert"
import { Product } from "../products/product"
import { useDashboardContext } from "../../../contexts/DashboardContext"
import { CustomSpinner } from "../../../components/CustomSpinner"
import { useNavigate } from "react-router-dom"
import { useMyProductsContext } from "../../../contexts/MyProductsContext"
import { OrderStatistics } from "./OrderStatistics"
import "./dashboard.scss"

export const Dashboard = () => {
  const nav = useNavigate()
  const {
    isLoading,
    summary,
    getSummary,
    bestsellers,
    getBestSellers,
    suggested,
    getSuggested,
    setPrieUpdatesActive
  } = useDashboardContext()
  const { setFilterParams } = useMyProductsContext()

  const {
    open_orders,
    out_of_stock,
    payment_pending,
    payment_pending_amount,
    price_updates,
    total_products
  } = summary

  useEffect(() => {
    Promise.allSettled([getSummary(), getBestSellers(), getSuggested()])
    setPrieUpdatesActive(false)
    setFilterParams({})
  }, [])

  return (
    <CustomContainer title="">
      <Row>
        <Col xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" className="mb-3">
          <Row>
            <Col xs="12" sm="12" md="12" lg="6" xxl="6" className="mb-3">
              <PaymentPendingCard
                title="Payment Pending"
                payment_pending={payment_pending}
                payment_pending_amount={payment_pending_amount}
                onClick={() =>
                  nav("/orders", {
                    state: { common_status: "U" }
                  })
                }
              />
            </Col>
            <Col xs="12" sm="12" md="12" lg="6" xxl="6" className="mb-3">
              <OpenOrdersCard
                title="Open Orders"
                open_orders={open_orders}
                onClick={() =>
                  nav("/orders", {
                    state: { common_status: "all" }
                  })
                }
              />
            </Col>
            <Col xs="12" sm="12" lg="12" xxl="12" className="mb-3">
              <OrderStatistics title="Order Statistics" />
            </Col>
            <Col xs="12" sm="12" lg="12" xxl="12" className="mb-3">
              <ProductAlert
                title="Product Alert"
                out_of_stock={out_of_stock}
                price_updates={price_updates}
                setFilterParams={setFilterParams}
                setPrieUpdatesActive={setPrieUpdatesActive}
              />
            </Col>
          </Row>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" className="mb-3">
          <Row>
            <Col>
              <h5
                style={{ color: "#27963C", fontWeight: "600" }}
                className="mb-2"
              >
                Your Bestsellers
              </h5>
            </Col>
          </Row>
          <Row className="mb-2">
            {bestsellers.map(product => (
              <Col xs="12" sm="12" md="6" lg="4" className="mb-3">
                <Product
                  key={product.id}
                  product={product.id}
                  {...product}
                  bestsellers={true}
                  onClick={() =>
                    nav(`/my-products/${product.id}`, {
                      state: {
                        redirectUrl: "/dashboard"
                      }
                    })
                  }
                />
              </Col>
            ))}
          </Row>
          <Row>
            <Col>
              <h5
                style={{ color: "#27963C", fontWeight: "600" }}
                className="mb-2"
              >
                Suggested Products
              </h5>
            </Col>
          </Row>
          <Row>
            {suggested.map(product => (
              <Col xs="12" sm="12" md="6" lg="4" className="mb-3">
                <Product
                  key={product.id}
                  product={product.id}
                  {...product}
                  bestsellers={true}
                  onClick={() =>
                    nav(`/browse-products/${product.id}`, {
                      state: {
                        redirectUrl: "/dashboard"
                      }
                    })
                  }
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <CustomSpinner loading={isLoading} />
    </CustomContainer>
  )
}
