import React, { useEffect } from "react"
import { CustomCard } from "../../../components/CustomCard"
import { StatisticsChart } from "./StatisticsChart"
import { useDashboardContext } from "../../../contexts/DashboardContext"

export const OrderStatistics = ({ title }) => {
  const { isLoading, analytics, getOrderAnalytics } = useDashboardContext()
  const { monthly_data, total_order, total_profit, total_sales } = analytics

  useEffect(() => {
    getOrderAnalytics()
  }, [])

  return (
    <CustomCard
      title={
        <>
          <h5>{title}</h5>{" "}
          <h6 className="ms-auto" style={styles}>
            Total Order: {`${total_order}`}
          </h6>
          <h6 className="ms-3" style={styles}>
            Total Sales: {`$${Number(total_sales).toFixed(2)}`}
          </h6>
          <h6 className="ms-3" style={styles}>
            Profit: {`$${Number(total_profit).toFixed(2)}`}
          </h6>
        </>
      }
      subTitle={<StatisticsChart data={monthly_data} />}
      className="OrderStatics"
    ></CustomCard>
  )
}

const styles = {
  color: "#444444"
}
