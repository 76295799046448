import React from "react"
import { Button, Spinner } from "react-bootstrap"
import "./CustomButton.scss"

export const CustomButton = ({
  label,
  variant = "primary",
  type = "submit",
  backgroundColor,
  styles,
  loading,
  ...rest
}) => (
  <Button
    className={backgroundColor ? backgroundColor : ""}
    variant={variant}
    type={type}
    style={styles}
    disabled={loading}
    {...rest}
  >
    {loading ? (
      <>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <span>Loading...</span>
      </>
    ) : (
      label
    )}
  </Button>
)
